import * as constants from '~/constants';

import colors from './colors';

const theme = {
  fontFamily: "'Manrope', sans-serif",
  app: {
    colors,
    dateFormats: constants.DATE_FORMATS,
    fontWeights: {
      regular: '400',
      medium: '500',
      strong: '650',
    },
  },
};

export default theme;
