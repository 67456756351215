import styled from '@emotion/styled';
import { Box, SimpleGrid } from '@mantine/core';

export const TeamMembersContainer = styled(SimpleGrid)`
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #efeff0;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const NoResultsIllustration = styled(Box)`
  width: 96px;
  height: 96px;
  flex-shrink: 0;
`;

export const ConnectorIcon = styled(Box)`
  width: 20px;
  height: 20px;
  color: #a4a3a7;
`;

export const CopyIcon = styled(Box)`
  width: 20px;
  height: 20px;
  color: #fff;
`;
