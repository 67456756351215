import { isDate, isString, round } from 'lodash-es';
import { get as getCookie } from 'es-cookie';
import prettyBytes from 'pretty-bytes';
import { formatDuration as formatDurationFromDateFns, intervalToDuration } from 'date-fns';
import { uid } from 'uid';
import { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

export const isValidURL = (value) => {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
};

export const getEnv = () => {
  const hostname = location.hostname;
  if (hostname === 'kello.ai') {
    return 'PRODUCTION';
  } else if (hostname === 'staging.kello.ai') {
    return 'STAGING';
  }
  return 'DEV';
};

export const getCsrfTokenFromCookie = () => {
  return getCookie('csrftoken');
};

export const formatNumber = (number, stripDecimals = true) => {
  const options = { style: 'decimal' };
  if (stripDecimals) {
    options.trailingZeroDisplay = 'stripIfInteger';
  }
  return number.toLocaleString('en-IN', options);
};

export const formatInr = (number, stripDecimals = true) => {
  const options = { style: 'currency', currency: 'INR' };
  if (stripDecimals) {
    options.trailingZeroDisplay = 'stripIfInteger';
  }
  return number.toLocaleString('en-IN', options);
};

export const calculatePercentage = (value, total, precision = 2) => {
  if (!total || total < 0) {
    return 0;
  }
  return round((value / total) * 100, precision);
};

export const formatDuration = ({ durationInSeconds }) => {
  return formatDurationFromDateFns(intervalToDuration({ start: 0, end: durationInSeconds * 1000 }))
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' seconds', 's')
    .replace(' second', 's');
};

export const formatBytes = (bytes, options) => {
  if (bytes === undefined || bytes === null) {
    return '';
  }
  return prettyBytes(bytes, options);
};

export const triggerFileDownloadFromBlob = ({ fileName, blob }) => {
  var link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const generateBlobFromCsvString = (csvAsString) => {
  // BOM support for special characters in Excel
  var byteOrderMark = '\ufeff';
  return new Blob([byteOrderMark, csvAsString], {
    type: 'text/csv;charset=utf-8;',
  });
};

export const getCloudinaryUserAvatarThumbnailUrl = ({ s3Url, size }) => {
  if (!s3Url || !size) return '';
  return `https://res.cloudinary.com/upraised/image/fetch/c_thumb,g_face,w_${size},h_${size},f_auto,q_85/${s3Url}`;
};

export const getCloudinaryCompanyLogoUrl = ({ s3Url, height }) => {
  if (!s3Url || !height) return '';
  return `https://res.cloudinary.com/upraised/image/fetch/h_${height},f_auto,q_85/${s3Url}`;
};

export const generateRandomUniqueId = (length = 12) => {
  return uid(length);
};

export const readFileAsDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file'));
      }
    };
    reader.readAsDataURL(file);
  });
};

export const createImageInstance = (src, ...constructorProps) => {
  return new Promise((resolve, reject) => {
    let img = new Image(...constructorProps);
    img.onload = () => resolve(img);
    img.setAttribute('crossOrigin', '');
    img.onerror = () => reject(new Error('Failed to create image from source'));
    img.src = src;
  });
};

export const canvasToBlob = (canvas, type, quality) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to create blob from canvas'));
        }
      },
      type,
      quality,
    );
  });
};

export const getHostnameFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname;
  } catch (error) {
    return null;
  }
};

export const isServer = () => {
  return typeof window === 'undefined';
};

export const isProductionHostname = (hostname) => {
  return ['kello.ai', 'demo.kello.ai'].includes(hostname);
};

export const isStagingHostname = (hostname) => {
  return ['staging.kello.ai'].includes(hostname);
};

/**
 * Safely flatten a nested JavaScript object.
 * Source: https://github.com/jessie-codes/safe-flat
 *
 * @param {Object} obj Object to flatten
 * @param {Object} [config] Configuration options
 * @param {Object} [config.delimiter] Delimiter
 * @param {Object} [config.skipArrayFlatten] Checks whether to flatten array
 */
export const flattenObject = (obj, config = {}) => {
  const { delimiter = '_', skipArrayFlatten = false } = config;

  const result = {};

  if (typeof obj !== 'object' || isDate(obj)) return obj;

  const flat = (original, stack, prev) => {
    if (!Object.values(original).length && prev) {
      result[prev] = original;

      return original;
    }

    Object.entries(original).forEach(([key, value]) => {
      const newKey = prev ? prev + delimiter + key : key;
      const skipFlattening = value instanceof Array && skipArrayFlatten;
      if (!skipFlattening) {
        if (typeof value === 'object' && value !== null) {
          stack.forEach((s) => {
            if (value === s && !isDate(value)) {
              value = '[Circular]';
            }
          });
          stack.push(value);

          if (typeof value === 'object' && !isDate(value)) {
            return flat(value, stack, newKey);
          }
        }
      }
      result[newKey] = value;
    });
  };

  flat(obj, [obj]);

  return result;
};

export const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text);
  } else {
    let textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
};

export const reactSvgComponentToMarkupString = (Component, props) =>
  `data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(createElement(Component, props)))}`;

export const formatNumberWithCommas = (number) => {
  if (isNaN(number) || number < 10000) {
    return number;
  }
  return Number(number).toLocaleString();
};

export const castToBoolean = (inputValue) => {
  if (!inputValue || !isString(inputValue)) {
    return null;
  }
  const trueValues = ['yes', 'true', '1'];
  const falseValues = ['no', 'false', '0'];

  if (trueValues.includes(inputValue.toLowerCase())) {
    return true;
  } else if (falseValues.includes(inputValue.toLowerCase())) {
    return false;
  }

  return null;
};

export const isUpraisedEmail = (email) => {
  if (!email) return false;
  const splittedEmail = email.split('@');
  let domain = '';
  if (splittedEmail.length === 2) {
    domain = splittedEmail[1];
  }
  return ['upraised.co'].includes(domain);
};

export const getRelativeTime = (date) => {
  const now = new Date();
  const inputDate = new Date(date);

  const yearsDifference = differenceInYears(now, inputDate);
  if (yearsDifference >= 1) {
    return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
  }

  const monthsDifference = differenceInMonths(now, inputDate);
  if (monthsDifference >= 1) {
    return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
  }

  const daysDifference = differenceInDays(now, inputDate);
  if (daysDifference >= 1) {
    return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
  }

  const hoursDifference = differenceInHours(now, inputDate);
  if (hoursDifference >= 1) {
    return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
  }

  const minutesDifference = differenceInMinutes(now, inputDate);
  if (minutesDifference >= 1) {
    return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
  }

  return 'Just now';
};

export const searchParamsIncludesUtmParameter = () => {
  const searchParams = new URLSearchParams(location.search);
  const utmParamFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

  return utmParamFields.some((param) => searchParams.has(param));
};
