import { getEnv, isProductionHostname } from '~/utils';

const GOOGLE_LOGIN_CLIENT_ID_BY_ENVIRONMENT = {
  PRODUCTION: '521387537993-8fs84pvr9s2dh6n1cdedkffp2n5ihrjj.apps.googleusercontent.com',
  STAGING: '137457888237-qkgb7nm9qfkl63baheut9aucvj2f15ls.apps.googleusercontent.com',
};

// const GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT = {
//   PRODUCTION: '758825435163-hb4ng1udfaujub2dfvp471t8hepdtce0.apps.googleusercontent.com',
//   STAGING: '50999275446-ekmltu8smgp9jkj5qskjea3g43fv3iud.apps.googleusercontent.com',
// };

const GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT = {
  PRODUCTION: '521387537993-8fs84pvr9s2dh6n1cdedkffp2n5ihrjj.apps.googleusercontent.com',
  STAGING: '137457888237-qkgb7nm9qfkl63baheut9aucvj2f15ls.apps.googleusercontent.com',
};

export const GOOGLE_LOGIN_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_LOGIN_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  }
  return GOOGLE_LOGIN_CLIENT_ID_BY_ENVIRONMENT.STAGING;
})();

export const GOOGLE_PERMISSIONS_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  }
  return GOOGLE_PERMISSIONS_CLIENT_ID_BY_ENVIRONMENT.STAGING;
})();

export const GOOGLE_REDIRECT_URI = (() => {
  if (getEnv() === 'PRODUCTION') {
    return 'https://kello.ai';
  } else if (getEnv() === 'STAGING') {
    return 'https://staging.kello.ai';
  }
  return 'https://localhost:8085';
})();

export const FORMSPARK_FORM_ID = '2u76IUKWq';
