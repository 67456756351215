import { format } from 'date-fns';
import queryString from 'query-string';

import { DATE_FORMATS } from '~/constants';

import httpClient from './client';

export const fetchFilteredJobApplications = (
  {
    pageSize,
    pageNumber,
    jobId,
    search,
    locations,
    companies,
    noticePeriod,
    minExp,
    maxExp,
    minSalary,
    maxSalary,
    applicationDateRangeStart,
    applicationDateRangeEnd,
    searchVersion,
  },
  options = {},
) => {
  const queryStr = {
    pageSize,
    pageNumber,
    jobId,
    search: search || undefined,
    locations: locations || undefined,
    companies: companies || undefined,
    noticePeriods: noticePeriod || undefined,
    searchVersion,
  };

  if (minExp || maxExp) {
    queryStr['workExperienceInYears.comparison'] = 'between';
    queryStr['workExperienceInYears.values'] = `${isNaN(minExp) || minExp === '' ? 0 : minExp},${
      isNaN(maxExp) || maxExp === '' ? 100 : maxExp
    }`;
  }
  if (minSalary || maxSalary) {
    queryStr['currentCTC.comparison'] = 'between';
    queryStr['currentCTC.values'] = `${isNaN(minSalary) || minSalary === '' ? 1 : minSalary},${
      isNaN(maxSalary) || maxSalary === '' ? 1000000000 : maxSalary
    }`;
  }
  if (applicationDateRangeStart && applicationDateRangeEnd) {
    queryStr['sourcedAt.comparison'] = 'between';
    queryStr['sourcedAt.values'] = `${format(applicationDateRangeStart, DATE_FORMATS.csvDate)},${format(
      applicationDateRangeEnd,
      DATE_FORMATS.csvDate,
    )}`;
  }

  const query = queryString.stringify(queryStr);

  return httpClient.get(`/api/v1/job-application?` + query, options);
};
