import { Box, Button, Flex, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { extractResponseError } from '~/api/utils';
import { PageContentLoader } from '~/components/molecules';
import { useRequestStates } from '~/hooks';
import { generateSearchQueryAttributes } from '~/utils/analytics';

import NoResultsIllustration from '~/assets/images/modals/search-history-modal/no-results.svg';

import * as Styles from './PromptSearchHistoryModal.styled';
import SearchHistory from './component/SearchHistory/SearchHistory';

const PromptSearchHistoryModal = ({ innerProps, context, id }) => {
  const { job, formInputFieldKeys, onUseQuery, trackEvent, fetchResultsApi } = innerProps;
  const [pageNumber, setPageNumber] = useState(1);
  const [searchHistories, setSearchHistories] = useState([]);
  const [fetchSearchHistoryRequestStates, fetchSearchHistoryRequestHandlers] = useRequestStates();
  const [loadMoreSearchHistoryRequestState, loadMoreSearchHistoryRequestHandlers] = useRequestStates();

  const handleUseQuery = ({ searchHistory, sequence }) => {
    const { parameters } = searchHistory;

    const formData = {
      [formInputFieldKeys.SEARCH]: parameters?.search || '',
      [formInputFieldKeys.LOCATIONS]: parameters?.locations || [],
      [formInputFieldKeys.COMPANIES]: parameters?.companies || [],
      [formInputFieldKeys.INSTITUTIONS]: parameters?.institutions || [],
      [formInputFieldKeys.SOURCED_BY]: parameters?.sourcedBy || [],
      [formInputFieldKeys.SOURCED_FROM]: parameters?.sourcedFrom || [],
      [formInputFieldKeys.NOTICE_PERIOD]: parameters?.noticePeriods || '',
      [formInputFieldKeys.MIN_SALARY]: parameters?.currentCTC?.values.length ? parameters?.currentCTC?.values[0] : '',
      [formInputFieldKeys.MAX_SALARY]: parameters?.currentCTC?.values.length ? parameters?.currentCTC?.values[1] : '',
      [formInputFieldKeys.MIN_EXP]: parameters?.workExperienceInYears?.values.length
        ? parameters?.workExperienceInYears?.values[0]
        : '',
      [formInputFieldKeys.MAX_EXP]: parameters?.workExperienceInYears?.values.length
        ? parameters?.workExperienceInYears?.values[1]
        : '',
      [formInputFieldKeys.APPLICATION_DATE_RANGE]: parameters?.sourcedAt?.values.length
        ? parameters?.sourcedAt?.values.map((v) => (v ? new Date(v) : null))
        : [null, null],
    };

    const appliedFilterKeys = Object.keys(snakecaseKeys(searchHistory.parameters)).filter(
      (parameter) => !['search', 'search_query'].includes(parameter),
    );

    trackEvent('attempted_to_use_search_history', {
      search: generateSearchQueryAttributes(searchHistory),
      search_filters: appliedFilterKeys,
    });

    onUseQuery({ formData, searchHistory, sequence });
    context.closeModal(id);
  };

  const fetchSearchHistory = async () => {
    const requestHandler = pageNumber > 1 ? loadMoreSearchHistoryRequestHandlers : fetchSearchHistoryRequestHandlers;

    try {
      requestHandler.pending();
      const payload = {
        pageNumber: pageNumber,
        pageSize: 5,
      };
      if (job) {
        payload.jobId = job.id;
      }
      const resp = await fetchResultsApi(payload);
      const { history, totalCount } = resp.result;
      setSearchHistories((p) => [...p, ...history]);
      setPageNumber((p) => p + 1);
      requestHandler.fulfilled({ history, totalCount });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      requestHandler.rejected(errorMessage);
    }
  };

  useEffect(() => {
    fetchSearchHistory();
  }, []);

  let nodeToRender;
  if (fetchSearchHistoryRequestStates.fulfilled) {
    let loadMoreBtnNode;
    let loaderNode;

    if (loadMoreSearchHistoryRequestState.pending) {
      loaderNode = <PageContentLoader />;
    }

    if (
      searchHistories.length <
      (fetchSearchHistoryRequestStates.data?.totalCount || loadMoreSearchHistoryRequestState.data?.totalCount)
    ) {
      loadMoreBtnNode = (
        <Flex justify="center" mt="24px">
          <Button
            size="xs"
            onClick={fetchSearchHistory}
            styles={{
              root: {
                background: '#5A47D7',
                border: '1px solid #7b6cdf',
              },
            }}
            loading={loadMoreSearchHistoryRequestState.pending}
          >
            Load More
          </Button>
        </Flex>
      );
    }

    nodeToRender = (
      <>
        <Box>
          {searchHistories.length ? (
            searchHistories.map((searchHistory, idx) => (
              <SearchHistory
                key={searchHistory.id}
                searchHistory={searchHistory}
                onUseQuery={() => handleUseQuery({ searchHistory, sequence: idx + 1 })}
              />
            ))
          ) : (
            <Flex
              justify="center"
              align="center"
              style={{
                flexDirection: 'column',
              }}
              h="64vh"
            >
              <Styles.NoResultsIllustrationContainer>
                <NoResultsIllustration />
              </Styles.NoResultsIllustrationContainer>
              <Box mt="48px">
                <Text c="#222" fz="26px" fw="650" ta="center">
                  It’s Empty in Here
                </Text>
                <Text c="#49484F" fz="12px" fw="450" mt="12px" ta="center">
                  You’ll find all your history of searches here as you do it
                </Text>
              </Box>
            </Flex>
          )}
        </Box>
        {loaderNode}
        {loadMoreBtnNode}
      </>
    );
  } else if (fetchSearchHistoryRequestStates.pending) {
    nodeToRender = <PageContentLoader mx="auto" />;
  } else if (fetchSearchHistoryRequestStates.rejected) {
    const errorMessage = fetchSearchHistoryRequestStates.error;

    nodeToRender = (
      <Flex direction="column" align="center" justify="center" py="56px">
        <Text>{errorMessage || 'Failed to load page content. Please try again.'}</Text>
        <Button
          mt="lg"
          size="sm"
          onClick={fetchSearchHistory}
          styles={{
            root: {
              background: '#5A47D7',
              border: '1px solid #7b6cdf',
            },
          }}
        >
          Try again
        </Button>
      </Flex>
    );
  }

  return (
    <Box
      p="24px"
      style={{
        borderRadius: '0 0 16px 16px',
      }}
    >
      {nodeToRender}
    </Box>
  );
};

export default PromptSearchHistoryModal;
