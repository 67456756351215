import styled from '@emotion/styled';
import { ActionIcon, Box, Button, Checkbox, Flex, List, Text, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { noop } from 'lodash-es';
import plur from 'plur';
import { useState, useEffect } from 'react';

import ChevronDownIcon from '~/assets/icons/chevron-down.svg';

const SearchableDropdown = ({
  name = 'option',
  data,
  placeholder = 'Select options',
  leftSection,
  value = [],
  onApplyChange = noop,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(data);

  useEffect(() => {
    if (Array.isArray(data)) {
      setFilteredOptions(data.filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setFilteredOptions([]);
    }
  }, [searchTerm, data]);

  const toggleOption = (optionVal) => {
    setSelectedOptions((prev) =>
      prev.includes(optionVal) ? prev.filter((item) => item !== optionVal) : [...prev, optionVal],
    );
  };

  const handleApply = () => {
    onApplyChange(selectedOptions);
    setIsOpen(false);
  };
  const clearFilters = () => {
    onApplyChange([]);
    setIsOpen(false);
  };

  return (
    <Box pos="relative" miw="180px">
      <Flex
        justify="flex-start"
        align="center"
        w="100%"
        p="8px 12px"
        style={{
          border: `1px solid ${value.length > 0 ? '#DEDAF7' : '#EFEFF0'}`,
          background: value.length > 0 ? '#F7F6FD' : '#ffffff',
          cursor: 'pointer',
          borderRadius: '8px',
          userSelect: 'none',
          color: '#666',
        }}
        onClick={() => {
          setSelectedOptions(value);
          setIsOpen((prev) => !prev);
        }}
      >
        {leftSection}
        <Text ml="8px" mr="auto" fz="11px" lh="11px" fw="400" c={value.length ? '#49484f' : '#acb5bd'}>
          {value.length > 0 ? `${value.length} ${plur(name, value.length)} selected` : placeholder}
        </Text>
        {isOpen ? (
          <ChevronIconWrapper component={ChevronDownIcon} />
        ) : (
          <ChevronIconWrapper component={ChevronDownIcon} style={{ transform: 'rotate(180deg)' }} />
        )}
      </Flex>
      {isOpen ? (
        <Box
          pos="absolute"
          mt="xs"
          w="100%"
          bg="#fff"
          style={{ zIndex: '9999', border: '1px solid #EFEFF0', borderRadius: '8px' }}
        >
          <Box
            p="xs"
            style={{
              borderBottom: '1px solid #EFEFF0',
            }}
          >
            <Box pos="relative">
              <TextInput
                size="xs"
                placeholder={`Search ${plur(name, 2)}`}
                color="#49484F"
                leftSection={<IconSearch size={14} stroke={1.5} color="#77767B" />}
                styles={{
                  input: {
                    border: `1px solid #EFEFF0`,
                  },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                //   {...form.getInputProps(QUERY_PARAMS.MAX_EXP)}
              />
            </Box>
          </Box>
          <List mah="150px" px="xs" listStyleType="none" style={{ overflow: 'auto' }}>
            {filteredOptions.map((option) => (
              <List.Item key={option.value} mx="0" p="0" mt="4px">
                <Checkbox
                  color="dark"
                  id={option.value}
                  size="xs"
                  label={option.label}
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => toggleOption(option.value)}
                  styles={{
                    label: {
                      userSelect: 'none',
                    },
                    input: {
                      background: selectedOptions.includes(option.value) ? '#5A47D7' : 'inherit',
                    },
                  }}
                />
              </List.Item>
            ))}
          </List>
          <Flex
            align="center"
            p="xs"
            style={{
              borderTop: '1px solid #EFEFF0',
            }}
          >
            <ActionIcon
              mr="xs"
              size="compact-xs"
              variant="outline"
              color="dark"
              aria-label="Clear All filters"
              styles={{
                root: {
                  border: '1px solid #EFEFF0',
                  flexShrink: '0',
                },
              }}
              onClick={clearFilters}
            >
              <IconX size={18} stroke={2} color="#77767B" />
            </ActionIcon>
            <Button
              size="compact-xs"
              w="100%"
              onClick={handleApply}
              styles={{
                root: {
                  background: '#5A47D7',
                  border: '1px solid #7b6cdf',
                },
              }}
            >
              Apply
            </Button>
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
};

export default SearchableDropdown;

const ChevronIconWrapper = styled(Box)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  color: #e0e0e0;
`;
