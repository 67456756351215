import styled from '@emotion/styled';
import { DatePickerInput } from '@mantine/dates';
import { isArray } from 'lodash-es';
import React, { forwardRef } from 'react';

const DatePickerFilter = forwardRef(({ styles, value, ...rest }, ref) => {
  let isDateRangeSelected = false;

  if (isArray(value)) {
    isDateRangeSelected = value.some((val) => val !== null);
  }

  return (
    <StyledDatePickerInput
      ref={ref}
      color="dark"
      styles={{
        input: {
          border: `1px solid ${isDateRangeSelected ? '#DEDAF7' : '#EFEFF0'}`,
          background: isDateRangeSelected ? '#F7F6FD' : '#ffffff',
        },
        ...styles,
      }}
      {...rest}
    />
  );
});

export default DatePickerFilter;

export const StyledDatePickerInput = styled(DatePickerInput)`
  button {
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;

    > span {
      color: #acb5bd;
    }
  }
`;
