import styled from '@emotion/styled';
import { Flex } from '@mantine/core';

export const PromptCard = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;

  &:hover {
    background: #fafafa;
  }
`;
