import { Box, Flex, Spoiler, Text } from '@mantine/core';
import { useState } from 'react';
import { format, isToday, isYesterday } from 'date-fns';

import { talentPoolApi } from '~/api';
import { extractResponseError } from '~/api/utils';
import { DATE_FORMATS } from '~/constants';
import { useRequestStates } from '~/hooks';
import { notifyError, notifySuccess } from '~/utils/notification.utils';

import SearchIcon from '~/assets/icons/search.svg';
import FilterIcon from '~/assets/icons/filter.svg';

import * as Styles from './SearchHistory.styled';

const Filter = ({ label, filters = [], isRange }) => {
  let numberOfFiltersApplied = 0;
  let appliedFiltersNode;

  if (isRange) {
    numberOfFiltersApplied = 1;
    appliedFiltersNode = (
      <>
        <Styles.AppliedFilter>
          <Text fz="11px" c="#77767B" fw="450">
            {filters[0]}
          </Text>
        </Styles.AppliedFilter>
        <Styles.AppliedFilter>
          <Text fz="11px" c="#77767B" fw="450">
            {filters[1]}
          </Text>
        </Styles.AppliedFilter>
      </>
    );
  } else if (filters.length) {
    numberOfFiltersApplied = filters.length;
    appliedFiltersNode = (
      <>
        {filters.map((sb) => (
          <Styles.AppliedFilter key={sb}>
            <Text fz="11px" c="#77767B" fw="450" maw="80px" truncate title={sb}>
              {sb}
            </Text>
          </Styles.AppliedFilter>
        ))}
      </>
    );
  }

  return (
    <Styles.FilterContainer>
      <Styles.AppliedFilterTypeContainer>
        <Text c="#77767B" fz="11px" fw="450" style={{ whiteSpace: 'nowrap' }}>
          {label}
        </Text>
        <Styles.NumberOfAppliedFilters ml="8px">{numberOfFiltersApplied}</Styles.NumberOfAppliedFilters>
      </Styles.AppliedFilterTypeContainer>
      <Flex bg="#F8F8F8" p="8px 12px">
        {appliedFiltersNode}
      </Flex>
    </Styles.FilterContainer>
  );
};

const SearchHistory = ({ searchHistory, onUseQuery, ...rest }) => {
  const { id: searchHistoryId, updatedAt, parameters, isSaved } = searchHistory;
  const [isSearchSaved, setIsSearchSaved] = useState(isSaved || false);
  // eslint-disable-next-line no-unused-vars
  const [saveTalentPoolHistoryRequestStates, saveTalentPoolHistoryRequestHandlers] = useRequestStates();

  // eslint-disable-next-line no-unused-vars
  const toggleSaveSearchHistoryBtnClick = async () => {
    try {
      saveTalentPoolHistoryRequestHandlers.pending();
      const payload = {
        isSaved: !isSearchSaved,
      };
      const resp = await talentPoolApi.updateTalentPoolHistory({
        searchHistoryId,
        payload,
      });
      saveTalentPoolHistoryRequestHandlers.fulfilled(resp.result);
      const { isSaved: isSearchHistorySaved } = resp.result;
      setIsSearchSaved(isSearchHistorySaved);
      const saveSuccessMessage = 'Saved the search.';
      const unsaveSuccessMessage = 'Unsaved the search.';
      notifySuccess({
        message: isSearchHistorySaved ? saveSuccessMessage : unsaveSuccessMessage,
      });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      saveTalentPoolHistoryRequestHandlers.rejected(errorMessage);
      notifyError({
        message: 'Something went wrong. Please try again later.',
      });
    }
  };

  const formatDate = (date) => {
    if (isToday(date)) {
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    }
    return format(date, DATE_FORMATS.date);
  };

  // TODO: Fix logic
  const hasAppliedFilters = Object.keys(parameters).length > 1;

  return (
    <Styles.PromptCard {...rest}>
      <Flex justify="space-between" align="center" w="100%" h="34px">
        <Flex align="center">
          <Text c="#A4A3A7" fz="11px" fw="450">
            {formatDate(new Date(updatedAt))}
          </Text>
          <Box component="span" w="2px" h="2px" bg="#A4A3A7" mx="4px" style={{ borderRadius: '50%' }} />
          <Text c="#A4A3A7" fz="11px" fw="450">
            {format(new Date(updatedAt), DATE_FORMATS.time)}
          </Text>
        </Flex>

        <Styles.UseNowButton
          size="xs"
          variant="outline"
          onClick={onUseQuery}
          styles={{
            root: {
              border: '1px solid #BDB5EF',
              borderRadius: '8px',
            },
            label: {
              color: '#5A47D7',
            },
          }}
        >
          Use Now
        </Styles.UseNowButton>
      </Flex>
      {parameters.search ? (
        <Flex mt="24px">
          <Styles.IconWrapper component={SearchIcon} />
          {parameters.search ? (
            <Spoiler
              maxHeight={40}
              showLabel="Show more"
              hideLabel="Hide"
              styles={{
                control: {
                  color: '#49484F',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  fontWeight: '450',
                },
              }}
              ml="24px"
            >
              <Text c="#49484F" fz="14px" fw="450" title={parameters.search}>
                {parameters.search}
              </Text>
            </Spoiler>
          ) : null}
        </Flex>
      ) : null}

      {hasAppliedFilters ? (
        <Flex mt="32px">
          <Styles.IconWrapper component={FilterIcon} mt="8px" />
          <Styles.FiltersContainer ml="24px">
            {parameters.companies?.length ? <Filter label="Companies" filters={parameters.companies} /> : null}
            {parameters.institutions?.length ? <Filter label="Sourced By" filters={parameters.institutions} /> : null}
            {parameters.sourcedBy?.length ? <Filter label="Sourced By" filters={parameters.sourcedBy} /> : null}
            {parameters.sourcedFrom?.length ? <Filter label="Sourced From" filters={parameters.sourcedFrom} /> : null}
            {parameters.sourcedAt?.values?.length ? (
              <Filter
                isRange
                label="Application Date"
                filters={parameters.sourcedAt.values.map((val) => format(new Date(val), DATE_FORMATS.date))}
              />
            ) : null}
            {parameters.workExperienceInYears?.values?.length ? (
              <Filter isRange label="Experience Between" filters={parameters.workExperienceInYears.values} />
            ) : null}
            {parameters.currentCTC?.values?.length ? (
              <Filter isRange label="Salary Between" filters={parameters.currentCTC.values} />
            ) : null}
            {parameters.noticePeriods ? <Filter label="Notice period" filters={[parameters.noticePeriods]} /> : null}
          </Styles.FiltersContainer>
        </Flex>
      ) : null}
    </Styles.PromptCard>
  );
};

export default SearchHistory;
