import queryString from 'query-string';

import httpClient from './client';

export const fetchJobsForOrg = ({ orgId, pageNumber, pageSize }, options = {}) => {
  const query = queryString.stringify({ orgId, pageNumber, pageSize });

  return httpClient.get(`/api/v1/job?` + query, options);
};

export const fetchJobDetails = ({ jobSlug }, options = {}) => {
  return httpClient.get(`/api/v1/job/${jobSlug}/`, options);
};

export const fetchFiltersForJob = ({ jobSlug }, options = {}) => {
  return httpClient.get(`/api/v1/job/${jobSlug}/filters/`, options);
};

export const fetchJobSearchHistory = ({ jobId, pageNumber, pageSize }, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/v1/job/${jobId}/search-history/?` + query, options);
};

export const updateJobSearchHistory = ({ jobId, searchHistoryId, payload }, options = {}) => {
  return httpClient.patch(`/api/v1/job/${jobId}/search-history/${searchHistoryId}`, payload, options);
};
