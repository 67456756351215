import styled from '@emotion/styled';
import { Box, Button, Flex, Text } from '@mantine/core';

export const UseNowButton = styled(Button)`
  display: none;
`;

export const PromptCard = styled(Box)`
  padding-bottom: 40px;
  border-bottom: 1px solid #efeff0;

  &:hover {
    ${UseNowButton} {
      display: block;
    }
  }

  & + & {
    padding-top: 24px;
  }
`;

export const IconWrapper = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: #77767b;
`;

export const FiltersContainer = styled(Flex)`
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

export const FilterContainer = styled(Flex)`
  align-items: center;
  border-radius: 8px;
  border: 1px solid #efeff0;
  background: #fff;
  overflow: hidden;
`;

export const AppliedFilterTypeContainer = styled(Flex)`
  align-items: center;
  border-right: 1px solid #efeff0;
  background: #fff;
  padding: 8px 12px;
`;

export const NumberOfAppliedFilters = styled(Text)`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  background: #16151c;
  color: #fff;
  font-size: 8px;
  font-weight: 650;
`;

export const AppliedFilter = styled(Flex)`
  align-items: center;

  & + & {
    padding-left: 6px;
    margin-left: 6px;
    border-left: 1px solid #efeff0;
  }
`;
