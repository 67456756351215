import httpClient from './client';

export const fetchUser = (options = {}) => {
  return httpClient.get(`/api/v1/user/`, options);
};

export const loginUser = ({ email, password }, options = {}) => {
  return httpClient.post(`/api/v1/user/login/`, { email, password }, options);
};

export const loginUserWithGoogle = (payload, options = {}) => {
  return httpClient.post(`/api/v1/user/auth/google`, payload, options);
};

export const fetchOnboardingInfo = (options = {}) => {
  return httpClient.get(`/api/v1/user/onboarding/info`, options);
};

export const onboardUser = ({ team, designation }, options = {}) => {
  return httpClient.patch(`/api/v1/user/onboarding`, { team, designation }, options);
};

export const setupAndSyncGmail = ({ authCode }, options = {}) => {
  return httpClient.post(`/api/v1/user/gmail/setup`, { authCode }, options);
};

export const fetchGmailSyncData = (options = {}) => {
  return httpClient.get(`/api/v1/user/gmail/setup`, options);
};
