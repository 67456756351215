import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Button } from '@mantine/core';
import { css, useTheme } from '@emotion/react';

const VARIANTS = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  TRANSPARENT: 'transparent',
};

const SIZES = {
  X_SMALL: 'x_small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const COLORS = {
  BRAND: 'brand',
  ACCENT: 'accent',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

const STATES = {
  DEFAULT: 'DEFAULT',
  HOVERED: 'HOVERED',
  PRESSED: 'PRESSED',
  LOADING: 'LOADING',
  FOCUSED: 'FOCUSED',
  DISABLED: 'DISABLED',
};

const getHeight = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 56;
    case SIZES.MEDIUM:
      return 48;
    case SIZES.SMALL:
      return 40;
    case SIZES.X_SMALL:
      return 32;
  }
};

const getWidth = ({ fullWidth }) => {
  return fullWidth ? '100%' : 'auto';
};

const getInlinePadding = ({ size, variant, iconOnly }) => {
  if (iconOnly) {
    switch (size) {
      case SIZES.LARGE:
        return 12;
      case SIZES.MEDIUM:
        return 10;
      case SIZES.SMALL:
        return 8;
      case SIZES.X_SMALL:
        return 6;
    }
  }

  if (variant === VARIANTS.TRANSPARENT) {
    switch (size) {
      case SIZES.LARGE:
        return 14;
      case SIZES.MEDIUM:
        return 12;
      case SIZES.SMALL:
        return 10;
      case SIZES.X_SMALL:
        return 8;
    }
  }

  switch (size) {
    case SIZES.LARGE:
      return 20;
    case SIZES.MEDIUM:
      return 18;
    case SIZES.SMALL:
      return 14;
    case SIZES.X_SMALL:
      return 12;
  }
};

//TODO: Recheck colors
const getColorStyles = ({ variant, color, theme }) => {
  if (variant === VARIANTS.FILLED) {
    switch (color) {
      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEUTRAL_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
        };

      default:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_INVERTED,
          [STATES.HOVERED]: theme.app.colors.TEXT_INVERTED,
          [STATES.PRESSED]: theme.app.colors.TEXT_INVERTED,
          [STATES.LOADING]: theme.app.colors.TEXT_INVERTED,
          [STATES.FOCUSED]: theme.app.colors.TEXT_INVERTED,
          [STATES.DISABLED]: theme.app.colors.TEXT_INVERTED,
        };
    }
  }

  if (variant === VARIANTS.OUTLINED) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_BRAND_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_BRAND_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_BRAND_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_BRAND_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_BRAND_WEAKEST,
        };

      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_ACCENT_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_ACCENT_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_ACCENT_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_ACCENT_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_ACCENT_WEAKEST,
        };

      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEGATIVE_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEGATIVE_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEGATIVE_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
        };

      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEUTRAL_WEAK,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.LOADING]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEUTRAL_WEAK,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
        };
    }
  }

  if (variant === VARIANTS.TRANSPARENT) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_BRAND_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_BRAND_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_BRAND_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_BRAND_WEAKEST,
        };

      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_ACCENT_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_ACCENT_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_ACCENT_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_ACCENT_WEAKEST,
        };

      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEGATIVE_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEGATIVE_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEGATIVE_WEAKEST,
        };

      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.HOVERED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.PRESSED]: theme.app.colors.TEXT_NEUTRAL_STRONG,
          [STATES.LOADING]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.TEXT_NEUTRAL_NORMAL,
          [STATES.DISABLED]: theme.app.colors.TEXT_NEUTRAL_WEAKEST,
        };
    }
  }
};

//TODO: Recheck colors
const getBackgroundColorStyles = ({ variant, color, theme }) => {
  if (variant === VARIANTS.OUTLINED) {
    return {
      [STATES.DEFAULT]: 'transparent',
      [STATES.HOVERED]: 'transparent',
      [STATES.PRESSED]: 'transparent',
      [STATES.LOADING]: 'transparent',
      [STATES.FOCUSED]: 'transparent',
      [STATES.DISABLED]: 'transparent',
    };
  }

  if (variant === VARIANTS.TRANSPARENT) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_BRAND_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_BRAND_WEAKER,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_BRAND_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_ACCENT_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_ACCENT_WEAKER,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_ACCENT_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_NEGATIVE_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_NEGATIVE_WEAKER,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_NEGATIVE_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: 'transparent',
          [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAKEST,
          [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_WEAK,
          [STATES.LOADING]: 'transparent',
          [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAKEST,
          [STATES.DISABLED]: 'transparent',
        };
    }
  }

  switch (color) {
    case COLORS.BRAND:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_BRAND_STRONGER,
        [STATES.HOVERED]: theme.app.colors.BG_BRAND_STRONG,
        [STATES.PRESSED]: theme.app.colors.BG_BRAND_STRONG,
        [STATES.LOADING]: theme.app.colors.BG_BRAND_WEAK,
        [STATES.FOCUSED]: theme.app.colors.BG_BRAND_STRONGER,
        [STATES.DISABLED]: theme.app.colors.BG_BRAND_WEAK,
      };
    case COLORS.ACCENT:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_ACCENT_NORMAL,
        [STATES.HOVERED]: theme.app.colors.BG_ACCENT_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_ACCENT_STRONG,
        [STATES.LOADING]: theme.app.colors.BG_ACCENT_WEAKER,
        [STATES.FOCUSED]: theme.app.colors.BG_ACCENT_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_ACCENT_WEAKER,
      };
    case COLORS.NEGATIVE:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_NEGATIVE_NORMAL,
        [STATES.HOVERED]: theme.app.colors.BG_NEGATIVE_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEGATIVE_STRONG,
        [STATES.LOADING]: theme.app.colors.BG_NEGATIVE_WEAKER,
        [STATES.FOCUSED]: theme.app.colors.BG_NEGATIVE_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEGATIVE_WEAKER,
      };
    case COLORS.NEUTRAL:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_NEUTRAL_WEAKER,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_WEAKER,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_WEAKER,
      };

    case COLORS.BRAND_INVERTED:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_SURFACE,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_NORMAL,
      };

    case COLORS.NEGATIVE_INVERTED:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_SURFACE,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_NORMAL,
      };

    case COLORS.NEUTRAL_INVERTED:
      return {
        [STATES.DEFAULT]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.HOVERED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.PRESSED]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.LOADING]: theme.app.colors.BG_NEUTRAL_NORMAL,
        [STATES.FOCUSED]: theme.app.colors.BG_NEUTRAL_WEAK,
        [STATES.DISABLED]: theme.app.colors.BG_NEUTRAL_NORMAL,
      };
  }
};

const getFontSize = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 18;
    case SIZES.MEDIUM:
      return 16;
    case SIZES.SMALL:
      return 14;
    case SIZES.X_SMALL:
      return 12;
  }
};

const getLineHeight = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 18;
    case SIZES.MEDIUM:
      return 16;
    case SIZES.SMALL:
      return 14;
    case SIZES.X_SMALL:
      return 12;
  }
};

const getBorderWidth = (variant, state) => {
  if (variant === VARIANTS.OUTLINED) {
    switch (state) {
      case STATES.DEFAULT:
        return 1;
      case STATES.HOVERED:
        return 2;
      case STATES.PRESSED:
        return 2;
      case STATES.LOADING:
        return 1;
      case STATES.FOCUSED:
        return 2;
      case STATES.DISABLED:
        return 1;
    }
  }

  return 0;
};

//TODO: Recheck colors
const getBorderColorStyles = ({ variant, color, theme }) => {
  if (variant === VARIANTS.OUTLINED) {
    switch (color) {
      case COLORS.BRAND:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_BRAND_NORMAL,
          [STATES.HOVERED]: theme.app.colors.BORDER_BRAND_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_BRAND_STRONG,
          [STATES.LOADING]: theme.app.colors.BORDER_BRAND_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_BRAND_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_BRAND_WEAKEST,
        };
      case COLORS.ACCENT:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_ACCENT_NORMAL,
          [STATES.HOVERED]: theme.app.colors.BORDER_ACCENT_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_ACCENT_STRONG,
          [STATES.LOADING]: theme.app.colors.BORDER_ACCENT_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_ACCENT_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_ACCENT_WEAKEST,
        };
      case COLORS.NEGATIVE:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_NEGATIVE_NORMAL,
          [STATES.HOVERED]: theme.app.colors.BORDER_NEGATIVE_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_NEGATIVE_STRONG,
          [STATES.LOADING]: theme.app.colors.BORDER_NEGATIVE_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_NEGATIVE_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_NEGATIVE_WEAKEST,
        };
      case COLORS.NEUTRAL:
        return {
          [STATES.DEFAULT]: theme.app.colors.BORDER_NEUTRAL_WEAK,
          [STATES.HOVERED]: theme.app.colors.BORDER_NEUTRAL_WEAK,
          [STATES.PRESSED]: theme.app.colors.BORDER_NEUTRAL_NORMAL,
          [STATES.LOADING]: theme.app.colors.BORDER_NEUTRAL_WEAKEST,
          [STATES.FOCUSED]: theme.app.colors.BORDER_NEUTRAL_WEAK,
          [STATES.DISABLED]: theme.app.colors.BORDER_NEUTRAL_WEAKEST,
        };
    }
  }

  return {
    [STATES.DEFAULT]: 'transparent',
    [STATES.HOVERED]: 'transparent',
    [STATES.PRESSED]: 'transparent',
    [STATES.LOADING]: 'transparent',
    [STATES.FOCUSED]: 'transparent',
    [STATES.DISABLED]: 'transparent',
  };
};

const getBorderRadius = ({ size }) => {
  switch (size) {
    case SIZES.LARGE:
      return 8;
    case SIZES.MEDIUM:
      return 8;
    case SIZES.SMALL:
      return 8;
    case SIZES.X_SMALL:
      return 6;
  }
};

const getTextTransform = ({ uppercase }) => {
  if (uppercase) return 'uppercase';
  return 'none';
};

const getIconWidth = ({ size, iconOnly }) => {
  if (iconOnly) {
    switch (size) {
      case SIZES.LARGE:
        return 32;
      case SIZES.MEDIUM:
        return 28;
      case SIZES.SMALL:
        return 24;
      case SIZES.X_SMALL:
        return 20;
    }
  }

  switch (size) {
    case SIZES.LARGE:
      return 28;
    case SIZES.MEDIUM:
      return 24;
    case SIZES.SMALL:
      return 20;
    case SIZES.X_SMALL:
      return 16;
  }
};

const getIconHeight = ({ size, iconOnly }) => {
  if (iconOnly) {
    switch (size) {
      case SIZES.LARGE:
        return 32;
      case SIZES.MEDIUM:
        return 28;
      case SIZES.SMALL:
        return 24;
      case SIZES.X_SMALL:
        return 20;
    }
  }

  switch (size) {
    case SIZES.LARGE:
      return 28;
    case SIZES.MEDIUM:
      return 24;
    case SIZES.SMALL:
      return 20;
    case SIZES.X_SMALL:
      return 16;
  }
};

const getIconGap = (size) => {
  switch (size) {
    case SIZES.LARGE:
      return 8;
    case SIZES.MEDIUM:
      return 6;
    case SIZES.SMALL:
      return 4;
    case SIZES.X_SMALL:
      return 4;
  }
};

const getDefaultContentPadding = ({ size, iconOnly }) => {
  if (iconOnly) {
    return 0;
  }
  switch (size) {
    case SIZES.LARGE:
      return 28;
    case SIZES.MEDIUM:
      return 24;
    case SIZES.SMALL:
      return 20;
    case SIZES.X_SMALL:
      return 16;
  }
};

const getOpticalAlignmentPadding = (size) => {
  switch (size) {
    case SIZES.LARGE:
      return 8;
    case SIZES.MEDIUM:
      return 6;
    case SIZES.SMALL:
      return 4;
    case SIZES.X_SMALL:
      return 4;
  }
};

const getOverlayBackground = ({ variant, color }) => {
  if (variant === VARIANTS.FILLED) {
    switch (color) {
      case COLORS.BRAND:
        return 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)';
      case COLORS.NEGATIVE:
        return 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)';
      case COLORS.ACCENT:
        return 'none';
      case COLORS.NEUTRAL:
        return 'none';
    }

    return 'none';
  }
};

const Root = styled(Button)`
  /* layout */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
  width: ${getWidth};

  /* space */
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${getInlinePadding}px;
  padding-right: ${getInlinePadding}px;

  /* color */
  color: ${({ $loading, colorStyles }) => ($loading ? colorStyles.LOADING : colorStyles.DEFAULT)};
  background-color: ${({ $loading, backgroundColorStyles }) =>
    $loading ? backgroundColorStyles.LOADING : backgroundColorStyles.DEFAULT};

  /* typography */
  font-size: ${getFontSize}px;
  font-weight: ${(props) => props.theme.app.fontWeights.medium};
  letter-spacing: -0.01em;
  line-height: ${getLineHeight}px;
  text-decoration: none;
  text-transform: ${getTextTransform};
  pointer-events: ${({ $loading }) => ($loading ? 'none' : 'auto')};

  /* border */
  outline: none;
  border: none;
  border-radius: ${getBorderRadius}px;
  box-shadow: inset 0 0 0
    ${({ variant, $loading, borderColorStyles }) =>
      `${getBorderWidth(variant, STATES.DEFAULT)}px ${
        $loading ? borderColorStyles.LOADING : borderColorStyles.DEFAULT
      }`};

  /* actions */
  user-select: none;
  cursor: pointer;

  &:hover {
    color: ${({ colorStyles }) => colorStyles.HOVERED};
    background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.HOVERED};
    box-shadow: inset 0 0 0
      ${({ variant, borderColorStyles }) => `${getBorderWidth(variant, STATES.HOVERED)}px ${borderColorStyles.HOVERED}`};
  }

  &:active {
    color: ${({ colorStyles }) => colorStyles.PRESSED};
    background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.PRESSED};
    box-shadow: inset 0 0 0
      ${({ variant, borderColorStyles }) => `${getBorderWidth(variant, STATES.PRESSED)}px ${borderColorStyles.PRESSED}`};
  }

  /* not using &:disabled because we have scenarios where anchor link can also be disabled  */
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          pointer-events: none;
          color: ${({ colorStyles }) => colorStyles.DISABLED};
          background-color: ${({ backgroundColorStyles }) => backgroundColorStyles.DISABLED};
          box-shadow: inset 0 0 0
            ${({ variant, borderColorStyles }) =>
              `${getBorderWidth(variant, STATES.DISABLED)}px ${borderColorStyles.DISABLED}`};
        `
      : null}

  /* adding focus only when anchor is not disabled, this feature is by default for buttons  */
  &:focus-visible {
    ${({ disabled }) =>
      !disabled
        ? css`
            outline: 2px solid ${({ theme }) => theme.colors.BORDER_ACCENT_NORMAL};
            outline-offset: 2px;
            color: ${({ $loading, colorStyles }) => ($loading ? colorStyles.LOADING : colorStyles.FOCUSED)};
            background-color: ${({ $loading, backgroundColorStyles }) =>
              $loading ? backgroundColorStyles.LOADING : backgroundColorStyles.FOCUSED};
            box-shadow: inset 0 0 0
              ${({ $loading, variant, borderColorStyles }) =>
                `${getBorderWidth(variant, STATES.FOCUSED)}px ${
                  $loading ? borderColorStyles.LOADING : borderColorStyles.FOCUSED
                }`};
          `
        : null}
  }
`;

const Overlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: ${getBorderRadius}px;
  background: ${getOverlayBackground};
`;

const IconWrapper = styled(Box)`
  width: ${getIconWidth}px;
  height: ${getIconHeight}px;
  flex-shrink: 0;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

/**
 *
 * @param {boolean} [loading]
 * @param {Object} props
 * @param {string} [props.variant] 'filled' | 'outlined' | 'transparent'
 * @param {string} [props.color] 'brand' | 'negative' | 'neutral'
 * @param {string} [props.size] 'large' | 'medium' | 'small' | 'x_small'
 * @param {React.ReactElement} [props.icon] use when we have iconOnly button
 * @param {React.ReactElement} [props.iconLeft]
 * @param {React.ReactElement} [props.iconRight]
 * @param {string} [props.text]
 * @param {string} [props.loadingText]
 * @param {boolean} disabled
 * @param {boolean} fullWidth
 * @param {boolean} uppercase
 * @param {any} iconWrapperCss
 * @param {any} rootCss
 * @param {any} rootAs
 * @param {function} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @returns {React.ReactElement}
 */
const ButtonV2 = forwardRef(({ loading, ...props }, ref) => {
  const {
    variant,
    color,
    size,
    icon,
    iconLeft,
    iconRight,
    text,
    loadingText,
    disabled,
    children,
    iconWrapperCss,
    rootCss,
    as: rootAs,
  } = props;
  const theme = useTheme();
  const backgroundColorStyles = getBackgroundColorStyles({ variant, color, theme });
  const borderColorStyles = getBorderColorStyles({ variant, color, theme });
  const colorStyles = getColorStyles({ variant, color, theme });
  const iconOnly = !!icon && !text && !children;
  const hasIconLeft = !!iconLeft;
  const hasIconRight = !!iconRight;
  const iconGap = getIconGap(size);
  const defaultContentPadding = getDefaultContentPadding({ size, iconOnly });
  const opticalAlignmentPadding = getOpticalAlignmentPadding(size);

  let contentNode = null;

  if (iconOnly) {
    contentNode = (
      <IconWrapper size={size} iconOnly={iconOnly} $loading={loading} css={iconWrapperCss}>
        {loading ? <LoaderSvgComponent /> : icon}
      </IconWrapper>
    );
  } else {
    if (loading && loadingText) {
      contentNode = loadingText;
    } else {
      contentNode = children || text || null;
    }
  }

  let tabIndex;
  if ((rootAs === 'a' || rootAs === Link) && disabled) {
    tabIndex = '-1';
  }

  /* adding padding on both sides for optical alignment, in case any icon is present */
  const contentPaddingLeft = hasIconLeft ? iconGap : hasIconRight ? opticalAlignmentPadding : defaultContentPadding;
  const contentPaddingRight = hasIconRight ? iconGap : hasIconLeft ? opticalAlignmentPadding : defaultContentPadding;

  return (
    <Root
      ref={ref}
      $loading={loading}
      iconOnly={iconOnly}
      colorStyles={colorStyles}
      borderColorStyles={borderColorStyles}
      backgroundColorStyles={backgroundColorStyles}
      css={rootCss}
      tabIndex={tabIndex}
      {...props}
    >
      <Overlay size={size} variant={variant} color={color} />
      {hasIconLeft && (
        <IconWrapper size={size} iconOnly={iconOnly} $loading={loading} css={iconWrapperCss}>
          {loading ? <LoaderSvgComponent /> : iconLeft}
        </IconWrapper>
      )}
      <Box pl={`${contentPaddingLeft}px`} pr={`${contentPaddingRight}px`}>
        {contentNode}
      </Box>
      {hasIconRight && (
        <IconWrapper size={size} iconOnly={iconOnly} $loading={loading} css={iconWrapperCss}>
          {loading ? <LoaderSvgComponent /> : iconRight}
        </IconWrapper>
      )}
    </Root>
  );
});

ButtonV2.VARIANTS = VARIANTS;
ButtonV2.COLORS = COLORS;
ButtonV2.SIZES = SIZES;

ButtonV2.displayName = 'ButtonV2';

ButtonV2.defaultProps = {
  variant: VARIANTS.FILLED,
  color: COLORS.BRAND,
  size: SIZES.MEDIUM,
};

ButtonV2.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  icon: PropTypes.node,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  text: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  uppercase: PropTypes.bool,
  iconWrapperCss: PropTypes.any,
  rootCss: PropTypes.any,
  onClick: PropTypes.func,
  children: PropTypes.node,
  'aria-label': function (props) {
    if (props.icon !== undefined) {
      if (props['text'] === undefined && props['aria-label'] === undefined) {
        return new Error('Require aria-label for iconOnly button');
      }
    }
  },
};

export default ButtonV2;
