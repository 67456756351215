import { useForm } from '@mantine/form';
import { Button, Flex, Radio, Text, Textarea } from '@mantine/core';

import { useRequestStates } from '~/hooks';

const ReportErrorModal = ({ innerProps, context, id }) => {
  const { jobApplication, formData } = innerProps;
  const form = useForm({ initialValues: { errorType: '', errorMessage: '' } });
  const [reportErrorRequestStates, reportErrorRequestHandlers] = useRequestStates();

  const reportError = async (values) => {
    try {
      reportErrorRequestHandlers.pending();
      const payload = {
        error_type: values.errorType,
        error_message: values.errorMessage,
        form_data: formData,
        candidate_application: jobApplication,
      };
      const resp = await fetch(
        `https://api.retool.com/v1/workflows/dff0b5cc-c4c2-47d4-a352-9b0618cc7eb1/startTrigger`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Workflow-Api-Key': 'retool_wk_0c2a0af151f94fa89d07ac28202b3fff',
          },
          body: JSON.stringify(payload),
        },
      );
      // console.log(resp);

      context.closeModal(id);
      reportErrorRequestHandlers.fulfilled(resp);
    } catch (error) {
      reportErrorRequestHandlers.rejected(error);
    }
  };

  return (
    <Flex
      align="flex-start"
      justify="center"
      direction="column"
      w="100%"
      component="form"
      onSubmit={form.onSubmit(reportError)}
    >
      <Radio.Group name="errorType" withAsterisk {...form.getInputProps('errorType')}>
        <Radio value="Parsing Error" label="Parsing Error" />
        <Radio mt="xs" value="Irrelevant Search" label="Irrelevant Search" />
        <Radio mt="xs" value="Others" label="Others" />
      </Radio.Group>
      <Textarea mt="md" w="100%" label="Give more details" {...form.getInputProps('errorMessage')} />
      {reportErrorRequestStates.rejected ? (
        <Text size="xs" c="red" fw="bold" ta="center" mt="xs">
          {reportErrorRequestStates.error?.message || 'Something went wrong! Please try again.'}
        </Text>
      ) : null}
      <Button
        mt="lg"
        color="dark"
        type="submit"
        loading={reportErrorRequestStates.pending}
        styles={{ root: { alignSelf: 'flex-end' } }}
      >
        Report
      </Button>
    </Flex>
  );
};

export default ReportErrorModal;
