import { notifications } from '@mantine/notifications';

export const notifySuccess = ({ message, autoClose = 3000, ...rest }) => {
  notifications.show({
    message,
    autoClose,
    color: '#5a47d7',
    ...rest,
  });
};

export const notifyError = ({ message, autoClose = 3000, ...rest }) => {
  notifications.show({
    message,
    autoClose,
    color: 'red',
    ...rest,
  });
};

export const notifyWarning = ({ message, autoClose = 3000, ...rest }) => {
  notifications.show({
    message,
    autoClose,
    color: 'yellow',
    ...rest,
  });
};
