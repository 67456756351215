import styled from '@emotion/styled';
import { TextInput } from '@mantine/core';
import React, { forwardRef } from 'react';

const TextInputFilter = forwardRef(({ styles, value, ...rest }, ref) => {
  return (
    <StyledTextInput
      ref={ref}
      color="dark"
      styles={{
        input: {
          border: `1px solid ${value ? '#DEDAF7' : '#EFEFF0'}`,
          background: value ? '#F7F6FD' : '#ffffff',
        },
        ...styles,
      }}
      {...rest}
    />
  );
});

export default TextInputFilter;

export const StyledTextInput = styled(TextInput)`
  input {
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;

    &::placeholder {
      color: #acb5bd;
    }
  }
`;
