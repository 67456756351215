import styled from '@emotion/styled';
import { Select } from '@mantine/core';
import React, { forwardRef } from 'react';

const SelectInputFilter = forwardRef(({ styles, value, ...rest }, ref) => {
  return (
    <StyledSelect
      ref={ref}
      color="dark"
      styles={{
        input: {
          border: `1px solid ${value ? '#DEDAF7' : '#EFEFF0'}`,
          background: value ? '#F7F6FD' : '#ffffff',
        },
        dropdown: {
          borderRadius: '8px',
        },
        ...styles,
      }}
      {...rest}
    />
  );
});

export default SelectInputFilter;

export const StyledSelect = styled(Select)`
  input {
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;

    &::placeholder {
      color: #acb5bd;
    }
  }
`;
