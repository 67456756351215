import { TextInput } from '@mantine/core';
import { forwardRef } from 'react';

const Input = forwardRef(({ styles, ...rest }, ref) => {
  const { input: inputStyles, label: labelStyles, ...restStyles } = styles || {};
  return (
    <TextInput
      ref={ref}
      styles={{
        input: {
          height: '48px',
          borderRadius: '8px',
          border: '1px solid #EFEFF0',
          ...inputStyles,
        },
        label: {
          color: '#49484F',
          fontSize: '14px',
          fontWeight: '650',
          lineHeight: '14px',
          letterSpacing: '-0.21px',
          marginBottom: '8px',
          ...labelStyles,
        },
        ...restStyles,
      }}
      {...rest}
    />
  );
});

export default Input;
