import { useEffect } from 'react';
import { Button, Flex, Text } from '@mantine/core';

import { jobApi, organisationApi, userApi } from '~/api';
import { extractResponseError } from '~/api/utils';
import { useAppContext } from '~/contexts/app';
import { useRequestStates } from '~/hooks';
import useAnalytics from '~/hooks/useAnalytics';

import { PageContentLoader } from '../molecules';

function AppShell({ children }) {
  const analytics = useAnalytics();

  const { user, setUser, setOrganisation, setNumberOfOrgJobs, setOrgUsers } = useAppContext();
  const [fetchUserRequestStates, fetchUserRequestHandlers] = useRequestStates();
  const [fetchOrgRequestStates, fetchOrgRequestHandlers] = useRequestStates();
  const [fetchOrgJobsRequestStates, fetchOrgJobsRequestHandlers] = useRequestStates();
  // eslint-disable-next-line no-unused-vars
  const [fetchOrgUsersRequestStates, fetchOrgUsersRequestHandlers] = useRequestStates();

  const getUser = async () => {
    try {
      fetchUserRequestHandlers.pending();
      const resp = await userApi.fetchUser();
      const user = resp.result;
      setUser(user);
      fetchUserRequestHandlers.fulfilled(user);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchUserRequestHandlers.rejected(errorObj);
    }
  };

  const getOrganisation = async () => {
    try {
      fetchOrgRequestHandlers.pending();
      const resp = await organisationApi.fetch();
      const organisationInfo = resp.result;
      setOrganisation(organisationInfo);
      fetchOrgRequestHandlers.fulfilled(organisationInfo);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchOrgRequestHandlers.rejected(errorObj);
    }
  };

  const getOrganisationJobs = async () => {
    try {
      fetchOrgJobsRequestHandlers.pending();
      const payload = {
        orgId: user.orgId,
        pageSize: 1,
      };
      const resp = await jobApi.fetchJobsForOrg(payload);
      setNumberOfOrgJobs(resp.result?.totalCount);
      fetchOrgJobsRequestHandlers.fulfilled(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchOrgJobsRequestHandlers.rejected(errorMessage);
    }
  };

  const getUsersInOrg = async () => {
    try {
      fetchOrgUsersRequestHandlers.pending();
      const resp = await organisationApi.fetchUsersForOrg();
      fetchOrgUsersRequestHandlers.fulfilled(resp.result);
      setOrgUsers(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchOrgUsersRequestHandlers.rejected(errorMessage);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      getOrganisation();
      getUsersInOrg();
      getOrganisationJobs();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      analytics.identify(user.id, {
        email: user.email,
        name: user.name,
        role: user.role,
        status: user.isBetaUser ? 'BETA' : 'NON_BETA',
      });
    }
  }, [user]);

  if (fetchUserRequestStates.fulfilled && fetchOrgRequestStates.fulfilled && fetchOrgJobsRequestStates.fulfilled) {
    return children;
  } else if (fetchUserRequestStates.pending || fetchOrgRequestStates.pending || fetchOrgJobsRequestStates.pending) {
    return <PageContentLoader />;
  } else if (fetchUserRequestStates.rejected || fetchOrgRequestStates.rejected || fetchOrgJobsRequestStates.rejected) {
    const { errorMessage, statusCode } = fetchUserRequestStates.error;

    if (![400, 401].includes(statusCode)) {
      // Any error other than 400 and 401 requires the error UI to be shown

      return (
        <Flex direction="column" align="center" justify="center" py="56px">
          <Text>{errorMessage || 'Failed to load page content. Please try again.'}</Text>
          <Button
            mt="lg"
            size="sm"
            onClick={getUser}
            styles={{
              root: {
                background: '#5A47D7',
                border: '1px solid #7b6cdf',
              },
            }}
          >
            Try again
          </Button>
        </Flex>
      );
    } else {
      /* We can continue rendering children.
       * Login check would automatically happen at route level
       */
      return children;
    }
  }
}

export default AppShell;
