import { Box, Button, Flex, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

import { Input } from '~/components/molecules';

const CollectionActionModal = ({ innerProps, context, id }) => {
  const { collection, onSaveChanges, requestStates = {} } = innerProps;

  const isEditing = !!collection;

  const form = useForm({ initialValues: { name: collection?.name || '', description: collection?.description || '' } });

  const handleOnSubmit = (values) => {
    onSaveChanges(values);
    context.closeModal(id);
  };

  let errorNode;

  if (requestStates.rejected) {
    const errorMessage = requestStates.error?.errorMessage || `Failed to ${isEditing ? 'save' : 'create'} collection.`;
    errorNode = (
      <Text size="14px" c="red" fw="bold" ta="center">
        {errorMessage}
      </Text>
    );
  }

  return (
    <Box component="form" onSubmit={form.onSubmit(handleOnSubmit)}>
      <Box p="24px">
        <Input
          styles={{
            root: {
              flex: 1,
            },
            input: {
              height: '56px',
              border: '0px',
              background: '#F8F8F8',
              padding: '16px',
            },
          }}
          label="Name Your Collection"
          placeholder="Collection"
          {...form.getInputProps('name')}
        />
        <Input
          styles={{
            root: {
              flex: 1,
            },
            input: {
              height: '56px',
              border: '0px',
              background: '#F8F8F8',
              padding: '16px',
            },
          }}
          label="Description (Optional)"
          placeholder="Description"
          mt="16px"
          {...form.getInputProps('description')}
        />
      </Box>
      <Flex
        p="24px"
        justify="space-between"
        align="center"
        style={{
          borderTop: '1px solid #EFEFF0',
        }}
      >
        {errorNode ? errorNode : <Box />}
        <Button
          styles={{
            root: {
              borderRadius: '8px',
              background: '#5A47D7',
              border: '1px solid #7B6CDF',
            },
          }}
          ml="12px"
          type="submit"
          loading={requestStates.pending}
        >
          {isEditing ? 'Save Changes' : 'Create Collection'}
        </Button>
      </Flex>
    </Box>
  );
};

export default CollectionActionModal;
