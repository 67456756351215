import { Box, Button, Flex, Text } from '@mantine/core';

const ConfirmationModal = ({ innerProps, context, id }) => {
  const { message = '', ctaBtnLabel, ctaBtnProps, requestStates, onConfirm } = innerProps;
  const { styles: ctaBtnStyles, ...ctaBtnRestProps } = ctaBtnProps || {};
  const { root: rootStyles, ...ctaBtnRestStyles } = ctaBtnStyles || {};

  const handleConfirmBtnClick = () => {
    onConfirm();
    context.closeModal(id);
  };

  let errorNode;

  if (requestStates?.rejected) {
    const errorMessage = requestStates.error?.errorMessage || `Something went wrong. Please try again after sometime.`;
    errorNode = (
      <Text size="14px" c="red" fw="bold" ta="center">
        {errorMessage}
      </Text>
    );
  }

  return (
    <Box>
      <Box p="24px">
        <Text c="#77767B" fz="16px" fw="450">
          {message}
        </Text>
      </Box>
      <Flex
        p="24px"
        justify="space-between"
        align="center"
        style={{
          borderTop: '1px solid #EFEFF0',
        }}
      >
        {errorNode ? errorNode : <Box />}
        <Button
          styles={{
            root: {
              borderRadius: '8px',
              background: '#5A47D7',
              border: '1px solid #7B6CDF',
              ...rootStyles,
            },
            ...ctaBtnRestStyles,
          }}
          ml="12px"
          onClick={handleConfirmBtnClick}
          loading={requestStates?.pending}
          {...ctaBtnRestProps}
        >
          {ctaBtnLabel}
        </Button>
      </Flex>
    </Box>
  );
};

export default ConfirmationModal;
