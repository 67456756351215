// import { IconBookmark, IconBookmarkFilled } from '@tabler/icons-react';
import { Button, Flex, Spoiler, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import plur from 'plur';
import { format } from 'date-fns';

import { jobApi } from '~/api';
import { extractResponseError } from '~/api/utils';
import { PageContentLoader } from '~/components/molecules';
import { DATE_FORMATS } from '~/constants';
import { useRequestStates } from '~/hooks';
import { notifyError, notifySuccess } from '~/utils/notification.utils';

import * as Styles from './JobApplicationsFilterQueriesModal.styled';

const SearchHistoryPrompt = ({ searchHistory, onUseQuery, ...rest }) => {
  const { id: searchHistoryId, parameters, isSaved, jobId } = searchHistory;
  const [isSearchSaved, setIsSearchSaved] = useState(isSaved || false);
  // eslint-disable-next-line no-unused-vars
  const [saveJobSearchHistoryRequestStates, saveJobSearchHistoryRequestHandlers] = useRequestStates();

  // eslint-disable-next-line no-unused-vars
  const toggleSaveSearchHistoryBtnClick = async () => {
    try {
      saveJobSearchHistoryRequestHandlers.pending();
      const payload = {
        isSaved: !isSearchSaved,
      };
      const resp = await jobApi.updateJobSearchHistory({
        jobId,
        searchHistoryId,
        payload,
      });
      saveJobSearchHistoryRequestHandlers.fulfilled(resp.result);
      const { isSaved: isSearchHistorySaved } = resp.result;
      setIsSearchSaved(isSearchHistorySaved);
      const saveSuccessMessage = 'Saved the search.';
      const unsaveSuccessMessage = 'Unsaved the search.';
      notifySuccess({
        message: isSearchHistorySaved ? saveSuccessMessage : unsaveSuccessMessage,
      });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      saveJobSearchHistoryRequestHandlers.rejected(errorMessage);
      notifyError({
        message: 'Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <Styles.PromptCard {...rest}>
      <Stack gap="xs" mt="4px" miw="0">
        {parameters.search ? (
          <Spoiler
            maxHeight={40}
            showLabel="Show more"
            hideLabel="Hide"
            styles={{
              control: {
                color: '#333',
                textDecoration: 'underline',
                fontSize: '10px',
              },
            }}
          >
            <Text size="sm" title={parameters.search}>
              Query: {parameters.search}
            </Text>
          </Spoiler>
        ) : null}
        {parameters.locations?.length ? (
          <Text size="sm">
            {plur('Location', parameters.locations.length)}: {parameters.locations.join(', ')}
          </Text>
        ) : null}
        {parameters.companies?.length ? (
          <Text size="sm">
            {plur('Company', parameters.companies.length)}: {parameters.companies.join(', ')}
          </Text>
        ) : null}
        {parameters.noticePeriods ? <Text size="sm">Notice period: {parameters.noticePeriods}</Text> : null}
        {parameters.currentCTC?.values?.length ? (
          <Text size="sm">Salary: Between {parameters.currentCTC.values.join(' and ')}</Text>
        ) : null}
        {parameters.workExperienceInYears?.values?.length ? (
          <Text size="sm">Experience: Between {parameters.workExperienceInYears.values.join(' and ')}</Text>
        ) : null}
        {parameters.sourcedAt?.values?.length ? (
          <Text size="sm">
            Applied On: From {format(new Date(parameters.sourcedAt.values[0]), DATE_FORMATS.date)} to{' '}
            {format(new Date(parameters.sourcedAt.values[1]), DATE_FORMATS.date)}
          </Text>
        ) : null}
      </Stack>
      <Flex direction="column" align="flex-end" ml="sm" style={{ flexShrink: 0 }}>
        <Button
          size="xs"
          onClick={onUseQuery}
          styles={{
            root: {
              background: '#5A47D7',
              border: '1px solid #7b6cdf',
            },
          }}
        >
          Use
        </Button>
        {/* <Button
          mt="xs"
          size="compact-xs"
          variant="outline"
          onClick={toggleSaveSearchHistoryBtnClick}
          loading={saveJobSearchHistoryRequestStates.pending}
          styles={{
            root: {
              border: '1px solid #7b6cdf',
            },
            label: {
              color: '#5a47d7',
            },
          }}
          // rightSection={
          //   isSearchSaved ? <IconBookmarkFilled size={16} stroke={1.5} /> : <IconBookmark size={16} stroke={1.5} />
          // }
        >
          {isSearchSaved ? 'Saved' : 'Save'}
        </Button> */}
      </Flex>
    </Styles.PromptCard>
  );
};

const JobApplicationsFilterQueriesModal = ({ innerProps, context, id }) => {
  const { job, formInputFieldKeys, onUseQuery } = innerProps;
  const [pageNumber, setPageNumber] = useState(0);
  const [searchHistories, setSearchHistories] = useState([]);
  const [fetchJobSearchHistoryRequestStates, fetchJobSearchHistoryRequestHandlers] = useRequestStates();
  const [loadMoreJobSearchHistoryRequestState, loadMoreJobSearchHistoryRequestHandlers] = useRequestStates();

  const handleUseQuery = ({ searchHistory, sequence }) => {
    const { parameters } = searchHistory;

    const formData = {
      [formInputFieldKeys.SEARCH]: parameters?.search || '',
      [formInputFieldKeys.LOCATIONS]: parameters?.locations || [],
      [formInputFieldKeys.COMPANIES]: parameters?.companies || [],
      [formInputFieldKeys.NOTICE_PERIOD]: parameters?.noticePeriods || '',
      [formInputFieldKeys.MIN_EXP]: parameters?.currentCTC?.values.length ? parameters?.currentCTC?.values[0] : '',
      [formInputFieldKeys.MAX_EXP]: parameters?.currentCTC?.values.length ? parameters?.currentCTC?.values[1] : '',
      [formInputFieldKeys.MIN_SALARY]: parameters?.workExperienceInYears?.values.length
        ? parameters?.workExperienceInYears?.values[0]
        : '',
      [formInputFieldKeys.MAX_SALARY]: parameters?.workExperienceInYears?.values.length
        ? parameters?.workExperienceInYears?.values[1]
        : '',
      [formInputFieldKeys.APPLICATION_DATE_RANGE]: parameters?.sourcedAt?.values.length
        ? parameters?.sourcedAt?.values.map((v) => (v ? new Date(v) : null))
        : [null, null],
    };

    onUseQuery({ formData, searchHistory, sequence });
    context.closeModal(id);
  };

  const fetchJobSearchHistory = async () => {
    const requestHandler =
      pageNumber > 1 ? loadMoreJobSearchHistoryRequestHandlers : fetchJobSearchHistoryRequestHandlers;

    try {
      requestHandler.pending();
      const payload = {
        jobId: job.id,
        pageNumber: pageNumber + 1,
        pageSize: 5,
      };
      const resp = await jobApi.fetchJobSearchHistory(payload);
      const { history, totalCount } = resp.result;
      setSearchHistories((p) => [...p, ...history]);
      setPageNumber((p) => p + 1);
      requestHandler.fulfilled({ history, totalCount });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      requestHandler.rejected(errorMessage);
    }
  };

  useEffect(() => {
    fetchJobSearchHistory();
  }, []);

  let nodeToRender;
  if (fetchJobSearchHistoryRequestStates.fulfilled) {
    nodeToRender = (
      <>
        <Stack gap="xs" mt="xs">
          {searchHistories.length ? (
            searchHistories.map((searchHistory, idx) => (
              <SearchHistoryPrompt
                key={searchHistory.id}
                searchHistory={searchHistory}
                onUseQuery={() => handleUseQuery({ searchHistory, sequence: idx + 1 })}
              />
            ))
          ) : (
            <Flex justify="center" mt="24px">
              <Text size="xs" color="#999">
                No history found
              </Text>
            </Flex>
          )}
        </Stack>
        {searchHistories.length <
        (fetchJobSearchHistoryRequestStates.data?.totalCount ||
          loadMoreJobSearchHistoryRequestState.data?.totalCount) ? (
          loadMoreJobSearchHistoryRequestState.pending ? (
            <PageContentLoader />
          ) : (
            <Flex justify="center" mt="24px">
              <Button
                size="xs"
                onClick={fetchJobSearchHistory}
                styles={{
                  root: {
                    background: '#5A47D7',
                    border: '1px solid #7b6cdf',
                  },
                }}
              >
                Load More
              </Button>
            </Flex>
          )
        ) : null}
      </>
    );
  } else if (fetchJobSearchHistoryRequestStates.pending) {
    nodeToRender = <PageContentLoader mx="auto" />;
  } else if (fetchJobSearchHistoryRequestStates.rejected) {
    const errorMessage = fetchJobSearchHistoryRequestStates.error;

    nodeToRender = (
      <Flex direction="column" align="center" justify="center" py="56px">
        <Text>{errorMessage || 'Failed to load page content. Please try again.'}</Text>
        <Button
          mt="lg"
          size="sm"
          onClick={fetchJobSearchHistory}
          styles={{
            root: {
              background: '#5A47D7',
              border: '1px solid #7b6cdf',
            },
          }}
        >
          Try again
        </Button>
      </Flex>
    );
  }

  return nodeToRender;
};

export default JobApplicationsFilterQueriesModal;
