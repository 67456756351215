import { createContext, useCallback, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { default as localStorageSlim } from 'localstorage-slim';

import { castToBoolean } from '~/utils';

const LS_KEY_TO_CHECK_IN_INVITE_POPUP_IS_DISMISSED = 'is_invite_popup_dismissed';

const AppContext = createContext();
AppContext.displayName = 'AppContext';

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useImmer(null);
  const [organisation, setOrganisation] = useImmer(null);
  const [orgUsers, setOrgUsers] = useImmer([]);
  const [numberOfOrgJobs, setNumberOfOrgJobs] = useImmer([]);
  const [isDevMode, setIsDevMode] = useState(false);
  const [isBetaMode, setIsBetaMode] = useState(true);
  const [applicationStatuses, setApplicationStatuses] = useImmer({});
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);
  const [isInvitePopupDismissed, setIsInvitePopupDismissed] = useState(
    castToBoolean(localStorageSlim.get(LS_KEY_TO_CHECK_IN_INVITE_POPUP_IS_DISMISSED)),
  );

  const dismissInvitePopup = () => {
    localStorageSlim.set(LS_KEY_TO_CHECK_IN_INVITE_POPUP_IS_DISMISSED, 'true', { ttl: 72 * 3600 });
    setIsInvitePopupDismissed(true);
  };

  const navbarWidth = useCallback(() => {
    if (isNavbarExpanded) {
      return '252px';
    }
    return '76px';
  }, [isNavbarExpanded])();

  const exposed = {
    user,
    setUser,

    organisation,
    setOrganisation,

    numberOfOrgJobs,
    setNumberOfOrgJobs,

    orgUsers,
    setOrgUsers,

    isDevMode,
    setIsDevMode,

    isBetaMode,
    setIsBetaMode,

    applicationStatuses,
    setApplicationStatuses,

    navbarWidth,
    isNavbarExpanded,
    setIsNavbarExpanded,

    isInvitePopupDismissed,
    dismissInvitePopup,
  };

  return <AppContext.Provider value={exposed}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
