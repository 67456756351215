import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Global, ThemeProvider } from '@emotion/react';
import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { BrowserHistory } from 'history';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import { useIsTabletOrMobileMedia } from '~/hooks';
import { useAppContext } from '~/contexts/app';

import faviconIcoFile from '~/assets/icons/favicon/favicon.ico';
import MobileIllustration from '~/assets/images/mobile-support-message/illustration.svg';

/**
 *
 * @param {{history: BrowserHistory}} props
 */
function AppRoot({ children, history }) {
  const location = useLocation();
  const theme = useMantineTheme();
  const isTabletOrMobileMedia = useIsTabletOrMobileMedia();
  const { user } = useAppContext();

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, email: user.email, name: user.name });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);

  // Listen for `UPDATE_AVAILABLE` broadcast event
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const channel = new BroadcastChannel('sw-broadcast-update');
      channel.onmessage = (event) => {
        if (event.data.type === 'UPDATE_AVAILABLE') {
          setIsAppUpdateAvailable(true);
        }
      };
    }
  }, []);

  // Do a full page reload during navigation if an app update is available
  useEffect(() => {
    const unregister = history.listen(({ location: { pathname, search, hash } }) => {
      if (isAppUpdateAvailable) {
        const url = `${pathname}${search}${hash}`;
        window.location.href = url;
      }
    });
    return unregister;
  }, [isAppUpdateAvailable]);

  const pathName = location.pathname;
  const routesNotSupportingMobileVersion = ['/app/talent-pool', '/app/collections', '/app/jobs'];
  const isMobileVersionNotAvailable = routesNotSupportingMobileVersion.some((route) => pathName.startsWith(route));

  let nodeToRender;

  if (isTabletOrMobileMedia && isMobileVersionNotAvailable) {
    nodeToRender = (
      <Flex h="100vh" justify="center" align="center" style={{ flexDirection: 'column' }} px="24px">
        <MobileIllustrationWrapper>
          <MobileIllustration />
        </MobileIllustrationWrapper>
        <Text fz="16px" fw="650" mt="84px">
          kello is not on mobile yet
        </Text>
        <Text fz="14px" fw="450" ta="center" c="#77767B" mt="8px">
          for the best experience with kello, please switch to a laptop or desktop.
        </Text>
      </Flex>
    );
  } else {
    nodeToRender = children;
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Kello</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <link rel="alternate icon" href={faviconIcoFile} />
      </Helmet>
      <Global />
      {nodeToRender}
    </ThemeProvider>
  );
}

export default AppRoot;

const MobileIllustrationWrapper = styled(Box)`
  width: 140px;
  height: 220px;
  flex-shrink: 0;

  > svg {
    width: 100%;
    height: 100%;
  }
`;
